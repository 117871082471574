<template>
  <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="fecharDialogConfiguracao">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Permissão {{ permissaoParams.nome }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headersRotina"
          :items="rotinas"
          :search="search"
          :loading="buscandoRotinas"
          loading-text="Carregando as rotinas..."
          locale="pt-BR"
          sort-by="nome"
          class="elevation-1 mt-10"
        >
          <template #top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Rotinas - Operações</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogAddRotina" max-width="550px" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on"> Adicionar </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Vincular Rotina - Ações com Permissão</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="formRotina" v-model="validFormRotina" @submit.prevent>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="editedItemRotina.rotina.id"
                              :items="itensRotina"
                              label="Rotina"
                              required
                              :rules="[obrigatorio]"
                              :disabled="editedIndex === -1 ? false : true"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="9" sm="9">
                            <v-select
                              v-model="editedItemRotina.operacoes"
                              :items="itensOperacoes"
                              label="Ações"
                              required
                              multiple
                              :rules="[obrigatorio]"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert v-if="showMessageErrorRotina" dense outlined type="error">
                              {{ messageError }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="fecharDialogInclusaoRotina"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="green darken-1"
                      text
                      :disabled="!validFormRotina"
                      @click="salvarRelacionamentoRotina"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>

            <v-toolbar flat class="pt-3 mb-3">
              <v-col cols="12" sm="12" md="6" class="pl-0">
                <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
              </v-col>
            </v-toolbar>
          </template>

          <template #[`item.operacoes`]="{ item }">
            {{
              item.operacoes
                .toLowerCase()
                .split(',')
                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(', ')
            }}
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon class="mr-2" v-on="on" @click="alterarRelacionamentoRotina(item)">
                    mdi-pencil
                  </v-icon>
                </template>
                <span> Editar </span>
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon v-on="on" @click="removerRelacionamentoRotina(item)"> mdi-delete </v-icon>
                </template>

                <span> Remover </span>
              </v-tooltip>
            </div>
          </template>

          <template #no-data>
            <span>Sem registros</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </v-dialog>
</template>

<script>
import PermissaoService from '@/services/usuario/permissao'
import RotinaService from '@/services/usuario/rotina'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import Snackbar from '@/models/ui/snackbar'
import { obrigatorio } from '@/shareds/regrasForm'

export default {
  components: {
    SnackbarMessage
  },

  props: ['showDialogConfiguracao', 'permissaoParams'],

  data: () => ({
    headersRotina: [
      { text: 'Rotina', value: 'rotina.descricao' },
      { text: 'Operacões Permitidas', value: 'operacoes' },
      { text: '', value: 'acoes', sortable: false }
    ],
    search: '',
    buscandoRotinas: false,
    rotinas: [],
    itensRotina: [],
    itensOperacoes: [
      { text: 'Acessar', value: 'ACESSAR', disabled: true },
      { text: 'Adicionar', value: 'ADICIONAR' },
      { text: 'Editar', value: 'EDITAR' },
      { text: 'Remover', value: 'REMOVER' }
    ],
    showDialog: false,
    dialogAddRotina: false,
    dialogDelete: false,
    validFormRotina: true,
    snackbar: new Snackbar(),
    messageError: '',
    editedItemRotina: {
      rotina: {
        id: '',
        nome: ''
      },
      operacoes: ['ACESSAR']
    },
    defaultItemRotina: {
      rotina: {
        id: '',
        nome: ''
      },
      operacoes: ['ACESSAR']
    },
    editedIndex: -1,
    showMessageErrorRotina: false,
    obrigatorio: obrigatorio
  }),

  watch: {
    showDialogConfiguracao() {
      return this.showDialogConfiguracao ? this.buscarInformacoes(this.permissaoParams.id) : ''
    }
  },

  methods: {
    async buscarInformacoes() {
      this.showDialog = true
      await this.buscarOpcoesRotina()
      await this.buscarRotinasDaPermissao()
    },

    alertError(error) {
      this.snackbar.snackTextParam = `${error.response.status} | ${
        error.response.data.message === undefined
          ? error.message
          : error.response.data.message
          ? error.response.data.message
          : 'Ocorreu um erro ao buscar as rotinas.'
      }`
      this.snackbar.snackTypeParam = 'error'
      this.snackbar.showSnackParam = true
    },

    displayError(error) {
      this.messageError = `${error.response.status} | ${
        error.response.data.message === undefined ? error.message : error.response.data.message
      }`
      this.showMessageErrorRotina = true
    },

    alertSuccess(message) {
      this.snackbar.snackTextParam = message
      this.snackbar.snackTypeParam = 'sucess'
      this.snackbar.showSnackParam = true
    },

    async buscarOpcoesRotina() {
      try {
        const response = await RotinaService.buscarTudo()
        this.itensRotina = response.map((item) => ({ text: item.descricao, value: item.id }))
      } catch (error) {
        this.alertError(error)
      }
    },

    async buscarRotinasDaPermissao() {
      try {
        this.buscandoRotinas = true
        const response = await PermissaoService.buscarRotinasDaPermissao(this.permissaoParams.id)

        this.rotinas = response.map((item) => ({
          rotina: item.rotina,
          operacoes: item.operacoes.sort().join()
        }))
      } catch (error) {
        const defaultMessage = 'Ocorreu um erro ao buscar as rotinas.'
        this.alertError(error, defaultMessage)
      } finally {
        this.buscandoRotinas = false
      }
    },

    fecharDialogConfiguracao() {
      this.rotinas = []
      this.showDialog = false
      this.$emit('update:showDialogConfiguracao', false)
    },

    fecharDialogInclusaoRotina() {
      this.$refs.formRotina.resetValidation()
      this.showMessageErrorRotina = false
      this.messageError = ''
      this.editedItemRotina = { ...this.defaultItemRotina }
      this.editedIndex = -1
      this.dialogAddRotina = false
    },

    alterarRelacionamentoRotina(item) {
      this.editedIndex = this.rotinas.indexOf(item)
      this.editedItemRotina = { ...item }
      this.editedItemRotina.operacoes = this.editedItemRotina.operacoes.split(',')
      this.dialogAddRotina = true
    },

    async removerRelacionamentoRotina(item) {
      try {
        await PermissaoService.removerRelacionamentoRotina(this.permissaoParams.id, item.rotina.id)
        const index = this.rotinas.indexOf(item)
        this.alertSuccess('Rotina removida do relacionamento com sucesso.')
        this.rotinas.splice(index, 1)
      } catch (error) {
        this.displayError(error)
      }
    },

    async salvarRelacionamentoRotina() {
      const rotinasIdArray =
        this.editedItemRotina.rotina.id instanceof Array
          ? this.editedItemRotina.rotina.id
          : [this.editedItemRotina.rotina.id]

      const data = {
        rotinas: rotinasIdArray,
        operacoes: this.editedItemRotina.operacoes
      }

      try {
        await PermissaoService.adicionarRelacionamentoRotina(this.permissaoParams.id, data)
        this.fecharDialogInclusaoRotina()
        this.alertSuccess('Rotina relacionada com sucesso.')

        await this.buscarRotinasDaPermissao(this.permissaoParams.id)
      } catch (error) {
        this.displayError(error)
      }
    }
  }
}
</script>
