var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-2"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.buscando,"loading-text":"Carregando as permissões...","items":_vm.permissoes,"item-class":"class","footer-props":{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"d-flex",attrs:{"fluid":""}},[_c('v-toolbar-title',[_vm._v(" Permissões ")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"550px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":!_vm.permiteAdicionar}},'v-btn',attrs,false),on),[_vm._v(" Adicionar ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","required":"","rules":[_vm.obrigatorio]},model:{value:(_vm.editedItem.nome),callback:function ($$v) {_vm.$set(_vm.editedItem, "nome", $$v)},expression:"editedItem.nome"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.showMessageError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.messageError)+" ")]):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.fecharDialog}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":!_vm.validForm},on:{"click":_vm.salvarPermissao}},[_vm._v(" Salvar ")])],1)],1)],1)],1)]},proxy:true},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [(item.ativo)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"disabled":!_vm.permiteEditar},on:{"click":function($event){return _vm.configurarPermissao(item)}}},on),[_vm._v(" mdi-cog ")])]}}],null,true)},[_c('span',[_vm._v(" Configurar ")])])],1)]}}],null,true)})],1)],1),_c('SnackbarMessage',{attrs:{"show-snack":_vm.snackbar.showSnackParam,"snack-text":_vm.snackbar.snackTextParam,"snack-type":_vm.snackbar.snackTypeParam},on:{"update:showSnack":function($event){return _vm.$set(_vm.snackbar, "showSnackParam", $event)},"update:show-snack":function($event){return _vm.$set(_vm.snackbar, "showSnackParam", $event)}}}),_c('PermissaoRotina',{attrs:{"show-dialog-configuracao":_vm.showDialogConfiguracao,"permissao-params":_vm.permissaoParams},on:{"update:showDialogConfiguracao":function($event){_vm.showDialogConfiguracao=$event},"update:show-dialog-configuracao":function($event){_vm.showDialogConfiguracao=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }