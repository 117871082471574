<template>
  <v-container fluid>
    <v-card class="mt-2">
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :loading="buscando"
          loading-text="Carregando as permissões..."
          :items="permissoes"
          item-class="class"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
        >
          <template #top>
            <v-container fluid class="d-flex">
              <v-toolbar-title> Permissões </v-toolbar-title>
              <v-spacer />

              <v-dialog v-model="dialog" max-width="550px">
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" :disabled="!permiteAdicionar" v-on="on">
                    Adicionar
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="validForm" @submit.prevent>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.nome"
                              label="Nome"
                              required
                              :rules="[obrigatorio]"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert v-if="showMessageError" dense outlined type="error">
                              {{ messageError }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="red darken-1" text @click="fecharDialog"> Cancelar </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      :disabled="!validForm"
                      @click="salvarPermissao"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
          </template>

          <template #[`item.ativo`]="{ item }">
            <v-icon v-if="item.ativo" color="green">mdi-check</v-icon>
            <v-icon v-else color="red">mdi-close</v-icon>
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteEditar"
                    v-on="on"
                    @click="configurarPermissao(item)"
                  >
                    mdi-cog
                  </v-icon>
                </template>

                <span> Configurar </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />

    <PermissaoRotina
      :show-dialog-configuracao.sync="showDialogConfiguracao"
      :permissao-params="permissaoParams"
    />
  </v-container>
</template>

<script>
import PermissaoService from '@/services/usuario/permissao'
import Permissao from '@/models/usuario/permissao'
import Snackbar from '@/models/ui/snackbar'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import PermissaoRotina from '@/components/aplicacao/permissoes/PermissaoRotina'
import { obrigatorio, emailInvalido } from '@/shareds/regrasForm'
import store from '@/store'

export default {
  name: 'Permissao',

  components: {
    SnackbarMessage,
    PermissaoRotina
  },

  data: () => ({
    permissoes: [],
    itensPerfil: [],
    headers: [
      { text: 'Nome', value: 'nome' },
      { text: '', value: 'acoes', width: '1%' }
    ],
    panel: true,
    buscando: false,
    dialog: false,
    permissaoParams: {
      id: '',
      nome: ''
    },
    editedItem: new Permissao(),
    defaultItem: new Permissao(),
    editedIndex: -1,
    validForm: true,
    obrigatorio: obrigatorio,
    emailInvalido: emailInvalido,
    showMessageError: false,
    showDialogConfiguracao: false,
    snackbar: new Snackbar()
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Adicionar permissão' : 'Editar permissão'
    },
    permiteAdicionar() {
      return store.getters.verificaPermissaoAcesso('Permissao', 'ADICIONAR')
    },
    permiteEditar() {
      return store.getters.verificaPermissaoAcesso('Permissao', 'EDITAR')
    }
  },

  created() {
    this.buscarPermissoes()
  },

  methods: {
    buscarPermissoes() {
      this.buscando = true

      PermissaoService.buscarTudo()
        .then((response) => (this.permissoes = response))
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os permissoes.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscando = false))
    },

    configurarPermissao(item) {
      this.permissaoParams = {
        id: item.id,
        nome: item.nome
      }
      this.showDialogConfiguracao = true
    },

    salvarPermissao() {
      this.showMessageError = false
      this.$refs.form.validate()

      if (!this.validForm) return

      PermissaoService.cadastrar(this.editedItem)
        .then((response) => {
          this.fecharDialog()
          this.snackbar.snackTextParam = 'Permissão cadastrada com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.permissoes.push(response)
        })
        .catch((erro) => {
          this.messageError = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao cadastrar o perfil.'
          }`
          this.showMessageError = true
        })
    },

    fecharDialog() {
      this.$refs.form.resetValidation()
      this.dialog = false
      this.showMessageError = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    }
  }
}
</script>
